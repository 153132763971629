import styled from 'styled-components'


export const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    color: white;
    height:56px;
    margin-right:0px;
    background-color: #fff;
    border-bottom: 2px solid #DCDCDC;
    width:100%;
    padding:15px;
    color: #000;
    position: fixed;
    left:0;
    right: 0;
    z-index: 2005;
`;

export const Button = styled.div`
    min-width:147px;
    height:36px;
    border-radius: 8px;
    background-color: ${props => props.theme.backgrounds.blue3};
    display: flex;
    gap:18px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color:${props => props.theme.backgrounds.blue4};
    font-weight: 600;

    @media(max-width: 1280px){
        display: none;
    }
`;

export const ImageContainer = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconStablishment = ({stroke}) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.72266 10.8561V16.5606C2.72266 17.6652 3.61809 18.5606 4.72266 18.5606H15.2757C16.3802 18.5606 17.2757 17.6652 17.2757 16.5606V10.8561" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M9.9987 10.4167C8.30013 10.4167 7.43248 8.75484 7.16993 8.14099C7.13489 8.05908 7.01059 8.04983 6.9653 8.12655C6.61005 8.72831 5.46077 10.4167 3.7487 10.4167C1.98135 10.4167 1.11358 8.61752 0.890997 8.07176C0.850143 7.97158 0.850525 7.86119 0.884736 7.75855L2.89257 1.73505C2.90618 1.69421 2.9444 1.66667 2.98744 1.66667H17.01C17.053 1.66667 17.0912 1.69421 17.1048 1.73505L19.1127 7.75855C19.1469 7.86119 19.1473 7.97158 19.1064 8.07176C18.8838 8.61752 18.016 10.4167 16.2487 10.4167C14.5366 10.4167 13.3873 8.72831 13.0321 8.12655C12.9868 8.04983 12.8625 8.05908 12.8275 8.14099C12.5649 8.75484 11.6973 10.4167 9.9987 10.4167Z" stroke={stroke} strokeWidth="1.5"/>
    </svg>
)

export const iconCalendar = ({fill}) => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 19C19 19.55 18.55 20 18 20H6C5.45 20 5 19.55 5 19V9H19V19ZM11 11H8C7.45 11 7 11.45 7 12V15C7 15.55 7.45 16 8 16H11C11.55 16 12 15.55 12 15V12C12 11.45 11.55 11 11 11Z" fill={fill}/>
    </svg>   
)

export const ImageUserContainer = styled(ImageContainer)`
    width: ${props => props.width ?? '32px'};
    height:  ${props => props.height ?? '32px'};
`;

export const ContainerDropDownIconUser = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;


export const InforHeaderWrapper = styled.div`
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const IconCloseSidBar = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 8px 0px;
    gap: 10px;
    width: 24px;
    height: 40px;
    border-radius: 35px;
    flex: none;
    order: 0;
    flex-grow: 0;

    cursor: pointer;
`;

export const IconLogo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12.18px;
    width: 103.91px;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer ;
`;

export const ContainerLogoFood = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 220px;
    height: 40px;
    flex: none;
    margin-left: 50px;
    order: 0;
    flex-grow: 0;

    @media(max-width:730px){
        width: auto;
    }

    @media(max-width:430px){
        display: none;
    }
`;
export const ContainerWrapperLogoFood = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;
    width: 172px;
    height: 40px;
    flex: none;
    order: 0;
    flex-grow: 1;
`;

export const StablishmentNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    gap:16px;
    font-size: 14px;
    width: 268px;

    p{
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    @media(max-width:730px){
        width: auto;
    }

    @media(max-width:500px){
        display: none;
    }

`;

export const UserInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    gap:16px;
    width: 400px;

    @media(max-width:730px){
        width: auto;
    }

    @media(max-width:430px){
        width: 100%;
    }
`;

export const CertificateInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap:8px;
    font-size: 14px;
    padding-right:16px;
    width: 100%;
    border-right: 2px solid #DCDCDC;
    height: 36px;

    @media(max-width: 900px){
        display: none;
        border: none;
        width: 100%;
    }
`;

export const UserInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap:22px;
    width: 192px;
    height: 36px;

    p{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }


    @media(max-width: 900px){
        display: none;
    }
`;

export const UserInfoDropDown = styled(UserInfo)`
    display:none;
    .dropdown{
        display: none;
    }

    .my-css{
        display: flex;
        outline: none;
        box-shadow: none;   
        display: ${props => props.theme.display.flex};
        align-items: ${props => props.theme.align_items.center};
        justify-content: ${props => props.theme.justify_content.space_between};
        width: 100%;
    }

    .establishment-name{
        display: none;
    }

    .dropdown-icon{
        display: flex;
        gap: 10px;
    }

    @media(max-width: 900px){
        display: flex;

        .dropdown{
            display: flex;
        }
    }

    @media(max-width:500px){
        justify-content: flex-end;
        
        .establishment-name{
            display: flex;
        }
    }

    @media(max-width: 430px){
        width: 100%;
    }
`;
