
import { useState, useEffect } from "react";
import { getToken } from "../services/auth";
import CountDeliveryAndTable from "../services/countDeliveryAndTable";
import audioPedido from '../assets/audio/audio-pedido.mp3'
import logoTutorial from '../assets/logoTutorial.svg';
import { store } from "../globalState/storeDelivery";
// import { socket } from "../socket";

let timerIdDelivery1;
let notificationSong = false;


export const useCountDelivery = () => {
    const [coutDelivery, setCoutDelivery] = useState(0);
    // const [socketInstance] = useState(socket())

    const getCountDelivery = async () => {
        try {
            const cnpj = getToken('CNPJ');
            const service = new CountDeliveryAndTable();
            const result = await service.getCountDelivery({
                cnpj
            })

            if (result.success === true) {
                setCoutDelivery(result.data[0].qtd);
            }

            const verifyDeliveryOPened = store.getState();
            if(verifyDeliveryOPened !== undefined){
                
                verifyDeliveryOPened.forEach(async (item) => {
                    if(item.istatus === 1 && !notificationSong){
                        notificationSong = true;
                        return  await NotificationDelivery();
                    }             
               })
            
            }
        
    
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if(window.location.pathname !== '/'){
            verifyUrl();   
            // socketInstance.connect();
            // socketInstance.on('@sisteutil',  () => getCountDelivery())
            timerIdDelivery();

        }

        return () => {
            clearInterval(timerIdDelivery1);
        }
    },[]) 

    const NotificationDelivery = async() => {
        let granted = false;
        const permission =  await Notification.requestPermission();
        granted = permission === 'granted' ? true : false;

        if (granted) {
            const notification = new Notification('Delivery', {
                body:'Novo Pedido!',
                renotify:false,
                requireInteraction: true,
                icon:logoTutorial,

            
            })
            const audio = new Audio(audioPedido);
            audio.play();
            audio.loop = true;
            
            notification.onclick = (event) =>  {
                event.preventDefault();
                notificationSong = false;
                audio.loop = false;
                audio.pause();
                notification.addEventListener('click', window.open('https://sistefood.com.br/Delivery', 'https://sistefood.com.br/Delivery'));
        
            }

            notification.onclose = (event) =>  {
                event.preventDefault();
                notificationSong = false;
                audio.pause();
            }

        }
    }

    const timerIdDelivery =  () => {
        timerIdDelivery1 = setInterval(() => getCountDelivery(), 10000);
    }

    const verifyUrl = () => {
     
        if(coutDelivery === 0) {
            getCountDelivery() ;
        }
    }

    return {coutDelivery}
}