import { useCallback, useEffect, useState } from "react";
import { getToken } from "../services/auth";
import CountDeliveryAndTable from "../services/countDeliveryAndTable";

let timerIdTable1;

export const useCountTable = () => {
    const [countTable, setCountTable] = useState(0);

    useEffect(()=> {
        if(window.location.pathname !== '/'){
            verifyUrl();
            timerIdTable();
        }

        return () => {
            clearInterval(timerIdTable1);
        }

    }, []);

    const timerIdTable =  () => {
        timerIdTable1 = setInterval(() => getCountTable(), 10000);
    }

    const getCountTable = async () => {
        try {
            const cnpj = getToken('CNPJ');
            const service = new CountDeliveryAndTable();
            const result = await service.getCountTable({
                cnpj
            })
            
            if (result.success === true) {
                setCountTable(result.data[0].qtd)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const verifyUrl = useCallback(() => {
        if (countTable === 0) {
            getCountTable();  
        }

    },[])


    return {countTable, setCountTable, getCountTable}
}