import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getToken, isAuthenticated } from "../services/auth";
import TopBar from "../shared/header";
import {Container} from '../shared/GlobalStyles/styles'
import { SideBar } from "../components/SideBar";
import { theme } from "../shared/GlobalStyles/theme";
import { PermissionRoutesPrimaries } from "./permissionRoutesPrimaries";

let blue4 = theme.backgrounds.blue4;
let red2 = theme.backgrounds.red2;
let colorRed2 = theme.colors.red2;
let small_red2 = theme.border.small_red2;
let very_small_red2 = theme.border.very_small_red2;

const RouteWrapper = ({ component: Component, ...rest }) => {
    verifyUrlToThema();

    return <PermissionRoutesPrimaries rest={rest}>
        <Route 
            {...rest}
            render = { props =>

                    isAuthenticated('LOGIN') ? (   
                        <Container>
                            <SideBar/>
                            <TopBar/>
                            <Component 
                                {...props} 
                                {...rest}
                                fiscal={rest.fiscal} 
                                nfe={rest.nfe}
                            />
                        </Container>    
                    ) :(
                        // redirecionando o usuario
                        <Redirect to={{ pathname: '/', state:{from: props.location } }} />
                
                    )}
        
            
        />
    </PermissionRoutesPrimaries>

//   Forma Antiga quando eu não validava a permissão do usuario pela rota raiz de cada componente

    // return(     
    //     <Route 
    //         {...rest}
    //         render = { props =>

    //                 isAuthenticated('LOGIN') ? (   
    //                     <Container>
    //                         <SideBar/>
    //                         <TopBar/>
    //                         <Component 
    //                             {...props} 
    //                             {...rest}
    //                             fiscal={rest.fiscal} 
    //                             nfe={rest.nfe}
    //                         />
    //                     </Container>    
    //                 ) :(
    //                     // redirecionando o usuario
    //                     <Redirect to={{ pathname: '/', state:{from: props.location } }} />
                
    //                 )}
        
            
    //     />

    // )
}


const verifyUrlToThema = () => {

    if(JSON.parse(getToken('SISTESHOP'))){
        theme.backgrounds.blue4 = theme.backgrounds.blue2;
        theme.backgrounds.red2 = theme.backgrounds.blue;
        theme.colors.red2 = theme.colors.blue;
        theme.border.small_red2 = theme.border.small_blue;
        theme.border.very_small_red2 = theme.border.very_small_blue;
    }
    else {
        theme.backgrounds.blue4 = blue4;
        theme.backgrounds.red2 = red2;
        theme.colors.red2 = colorRed2;
        theme.border.small_red2 = small_red2;
        theme.border.very_small_red2 = very_small_red2;
    }
}

export default RouteWrapper;