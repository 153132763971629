import styled from "styled-components";


export const TableActions = styled.div`
    padding: 16px 24px 24px;
    height: 88px;
    width: 100%;
    position:${props => props.position !== undefined ? props.position : 'absolute'};
    bottom:0;
    display: flex;
    justify-content: space-between;
    background: ${props => props.theme.backgrounds.white};
    border-top: ${props => props.theme.border.very_small_gray5};
`;

export const LeftItems = styled.div`
    display: flex;
    align-items: center;
    gap:${props => props.theme.gaps.more_less_big};
`;

export const RightItems = styled(LeftItems)`
    justify-content: flex-end;
    width: ${props => props.width !== undefined ? props.width : ''};
    
`;

export const RedirectButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.paddings.small} ;
    gap: ${props => props.theme.gaps.small};
    width: ${props=>props.width};
    height: ${props=>props.height};
    border-radius: ${props => props.theme.border_radius.small};
    line-height: ${props => props.theme.lines_height.medium};
    border: ${props=>props.variant === 1? props.theme.border.very_small_red2 : 'none'};
    background-color:${props=> props.variant === 1 ? props.theme.backgrounds.white : props.theme.backgrounds.red2} ;
    font-weight: ${props=>props.fontWeight};
    font-size: ${props=>props.fontSize};
    color:${props=> props.variant === 1 ? props.theme.colors.red2 : props.theme.colors.white };
`;

export const Icons = styled.img`
    width: 20px;
    height:20px;
    cursor: pointer;
`;
